import React, { useState } from "react"
import { graphql } from 'gatsby'
import LayoutPublic from "../components/layouts/layoutPublic"
import { Box, Button, Heading, Image, SimpleGrid, Spinner, Flex } from "@chakra-ui/react"
import Header from "../components/header/index"
import AdsSummary from "../components/lists/adsSummary"
import ModelAdsTable from "../components/ModelAdsTable"
import ModelStats from "../components/ModelStats"
import WysiwygContent from "../components/content/content";
import Seo from '../components/Seo/';
import StructuredProductComparison from "../components/StructuredData/productComparison";
import Loadable from 'react-loadable'
import VisibilitySensor from 'react-visibility-sensor'
import HrefLang from '../utils/HrefLang'
import { FormattedMessage } from 'react-intl'
import europeMap from '../images/europeMap.svg'
import Wrapper from '../components/Wrapper'
import Nav from "../components/nav"

const ModelVersionTpl = (props) => {
  const
    [isAdsVisible, setAdsVisible] = useState(false),
    [isMapVisible, setMapVisible] = useState(false);

  const {
    refUnivers,
    refBrand,
    crew,
    launchYear,
    weight,
    designer
  } = props.data.model

  const {
    contentful_id,
    name,
    slug,
    refModel,
    intro,
    imageAsset,
    contentPrimary,
    contentSecondary
  } = props.data.version

  const {
    modelVersionOtherLang,
    ads,
    models
  } = props.data

  // const { universID } = props.pageContext
  const modelStat = props.data.stats ? props.data.stats.edges[0].node : null;
  const adsBecameVisible = (isVisible) => {
    if (isVisible && !isAdsVisible) {
      setAdsVisible(true)
    }
  }

  const AsyncAdsPanel = (isAdsVisible) ? Loadable({
    loader: () => import('../components/ad/AsyncAdsPanel'),
    loading() {
      return <Flex
        justify="center"
        border="dashed 2px"
        borderRadius="lg"
        borderColor="gray.100"
        background="gray.50"
        p="1rem"
        my="1rem"
        key={0}><Spinner color="blue.500" size="sm" />
      </Flex>
    }
  }) : null

  const ModelMap = (isMapVisible) ?
    Loadable({
      loader: () => import("../components/Maps/ModelMap"),
      loading() {
        return <Box>Chargement de la carte...</Box>
      }
    })
    : null

  const title = {
    "fr":
      name +
      " " +
      refUnivers.name.toLowerCase() +
      " d'occasion à vendre, achat/vente"
    ,
    "en":
      "Used " +
      name +
      " " +
      refUnivers.name.toLowerCase() +
      " for sale, price/buying/selling a boat"
  };
  const description = {
    "fr":
      name +
      " " +
      refUnivers.name.toLowerCase() +
      " d'occasion et au meilleur prix à vendre en France et en Europe, annonces prix, achat, vente. Wanaboat.fr spécialiste achat/vente " +
      name +
      " d'occasion."
    ,
    "en":
      name + " " + refUnivers.name.toLowerCase() + " used and at the best price for sale in Europe, price listings, buy, sell. iWannaboat, the specialist in buying and selling used " + name + " " + refUnivers.name.toLowerCase()
  };
  return (
    <LayoutPublic lang={process.env.GATSBY_LANG}>
      {modelVersionOtherLang ? <HrefLang lang="fr" slug={modelVersionOtherLang.slug} /> : null}
      <Seo
        title={
          title[process.env.GATSBY_LANG]
        }
        description={description[process.env.GATSBY_LANG]

        }
        slug={slug}
      />
      <StructuredProductComparison
        name={name}
        lowPrice={2000}
        highPrice={10000}
        offerCount={ads ? ads.edges.length : 0}
        refBrand={refBrand}
        slug={slug}
        images={
          ads.edges[0] && ads.edges[0].images
            ? ads.edges[0].node.images.url
            : null
        }
      />
      <Header
        nav={<Nav />}
        image={imageAsset}
        name={name}
        breadcrumbsItems={[refUnivers, { "name": refModel.name, "slug": refModel.slug }, { "name": name, "slug": slug }]}
        // singuralName={singuralName}
        slug={slug}
        desc={
          contentPrimary ?
            <WysiwygContent data={JSON.parse(contentPrimary.raw)} references={contentPrimary.references} />
            :
            ''
        }
      // desc={<WysiwygContent data={json} />}
      />
      {/* <AdsSummary ads={ads} universName={name} /> */}

      <AdsSummary ads={ads} universName={name} orientation={'portrait'} />


      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        gridGap="2rem"
      >
        <ModelStats
          ads={ads}
          modelName={name}
          universName={refUnivers.singularName.toLowerCase()}
          soldBoats={modelStat ? modelStat.soldBoats : null}
          launchYear={launchYear}
          boatDesigner={designer}
          boatCrew={crew}
          boatWeight={weight ? weight.toString() : null}
        />
        <Box
          display={{ base: 'none', lg: 'initial' }}
          position="relative"
          borderRadius="md"
          overflow="hidden"
        >
          {isMapVisible && ModelMap ?
            <ModelMap ads={ads} />
            :
            <>
              <Image
                src={europeMap}
                alt="Map"
                borderRadius='10px'
                h='500px'
                w='100%'
                objectFit='cover'
              />
              <Flex
                justifyContent="center"
                alignItems="center"
                position="absolute"
                top='0'
                left='0'
                w='100%'
                h='100%'
                background='radial-gradient(rgba(255,255,255,.5), rgba(255,255,255,.2))'
              >
                <Button
                  colorScheme="green"
                  boxShadow="lg"
                  onClick={() => { setMapVisible(true) }}
                >
                  <FormattedMessage id="model.see.europe.map.of.model" values={{ modelName: name }} />
                </Button>
              </Flex>
            </>
          }
        </Box>
      </SimpleGrid>
      <VisibilitySensor
        onChange={adsBecameVisible}
      >
        {isAdsVisible ?
          <AsyncAdsPanel
            baseSlug={slug}
            minPrice={0}
            maxPrice={1000}
            versionID={contentful_id}
            models={models}
            isModelsMobileOpen={false}
            scrolling={false}
            switchViewForBrowsing={() => { this.setState({ isUserFilters: true }) }}
          />

          :
          <Flex
            mx={{ base: 0, md: "2rem", lg: "2rem", xxl: "10rem" }}
            justify="center"
            border="dashed 2px"
            borderRadius="lg"
            borderColor="gray.100"
            background="gray.50"
            p="1rem"
            my="1rem"
            key={0}><Spinner color="blue.500" size="sm" />
          </Flex>}
      </VisibilitySensor>
      <SimpleGrid columns={{ base: 1, lg: 2 }} gap="2rem" p="2rem" my="2rem" backgroundColor="white">
        <Box maxW="100%" overflow="scroll">
          <Heading as="h2" textTransform="uppercase" fontSize="18px" fontWeight="normal" color="blue.600" letterSpacing=".05rem" mb="1.5rem">
            <FormattedMessage id="model.price.table.title" values={{ modelName: name }} />
          </Heading>
          <ModelAdsTable ads={ads} modelName={name} />
        </Box>
        <Box>
          {props.data.version.contentSecondary ?
            <>
              <Heading as="h2" textTransform="uppercase" fontSize="18px" fontWeight="normal" color="blue.600" letterSpacing=".05rem" mb="1.5rem">
                <FormattedMessage id="model.presentation.title" values={{ modelName: name }} />
              </Heading>
              <WysiwygContent data={JSON.parse(props.data.version.contentSecondary.raw)} />
            </>
            : null}
        </Box>
      </SimpleGrid>


    </LayoutPublic>
  )
}

export default ModelVersionTpl


export const pageQuery = graphql`
  query modelVersionQuery($slug: String!, $refModelContentfulID: String!, $contentfulID: String!, $lang: String! ) {
    version: contentfulModelVersion( contentful_id: { eq: $contentfulID }){
      contentful_id
      name
      slug
      imageAsset {
        gatsbyImageData(
          width:600,
          height:600,
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      intro{
        intro
      }
      contentPrimary{
        raw
        references {
          #... on ContentfulBrand { contentful_id slug }
          ... on ContentfulModel { contentful_id slug }
          #... on ContentfulUnivers { contentful_id slug }
        }
      }
      contentSecondary{ raw }
      refModel{
        name,
        slug
      }
    }
    model: contentfulModel(contentful_id: { eq: $refModelContentfulID }) {
      contentful_id
      slug
      name
      image
      vipEmailExample
      crew
      launchYear
      designer
      weight
      #childContentfulModelDescriptionRichTextNode {
      #  json
      #}
      #childContentfulModelContentSecondaryRichTextNode {
      #  json
      #}
      refUnivers {
        name
        singularName
        slug
      }
      refVersions {
        id
        name
        slug
      }
      refBrand {
        name
      }
      modelCharacteristics {
        data {
          key
          value
        }
      }
      refVersions {
        name
        slug
      }
      stolenData {
        name
        mail
        phone
        date
        content
      }
    }
    versionOtherLang: contentfulModelVersion( contentful_id: { eq: $contentfulID}){
      slug
    }
    ads: allContentfulAd(
      filter: {
          refVersion: { contentful_id: { eq: $contentfulID } }
          node_locale: { eq: $lang }
      }
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          contentful_id
          id
          name
          slug
          price
          department
          isBoat
          isPart
          isSail
          isTrolley
          country
          refBrand {
            name
          }
          pictures {
            url
          }
          user{
            id
          }
          refModel{ name}
          date(formatString: "Y")
          publicationDate(formatString: "D/M/Y")
          refUnivers {
            slug
          }
          location {
            lat
            lon
          }
        }
      }
    }
    boatAdsByPrice: allContentfulAd(
      filter: {
        isBoat: { eq: true }
        price: { gt: 1 }
        refModel: { id: { eq: $refModelContentfulID } }
      }
      sort: { fields: [price], order: ASC }
    ) {
      edges {
        node {
          id
          name
          slug
          price
          department
          isBoat
          isPart
          isSail
          isTrolley
          refBrand {
            name
          }
          images {
            url
          }
          date(formatString: "Y")
          publicationDate(formatString: "D/M/Y")
          refUnivers {
            slug
          }
        }
      }
    }
    cheapAd: allContentfulAd(
      filter: { refModel: { slug: { eq: $slug } } }
      limit: 1
      sort: { fields: price, order: ASC }
    ) {
      edges {
        node {
          price
        }
      }
    }
    expensiveAd: allContentfulAd(
      filter: { refModel: { slug: { eq: $slug } } }
      limit: 1
      sort: { fields: price, order: DESC }
    ) {
      edges {
        node {
          price
        }
      }
    }
    boatOnlyAds: allContentfulAd(
      filter: { isBoat: { eq: true }, refModel: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          price
        }
      }
    }
    partOnlyAds: allContentfulAd(
      filter: { isPart: { eq: true }, refModel: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          price
        }
      }
    }
    sailOnlyAds: allContentfulAd(
      filter: { isSail: { eq: true }, refModel: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          price
        }
      }
    }
  }
`;