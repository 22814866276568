import React from "react"
import { FormattedMessage } from "react-intl"
import { Box, SimpleGrid, Text, Heading } from "@chakra-ui/react"

function getPrices(ads) {
    let prices = []
    for (let index = 0; index < ads.length; index++) {
        const ad = ads[index].node;
        if (
            ad.isBoat
            && ad.price
            && ad.price > 1
            // && ad.action === 'sell'
        ) {
            prices.push(ad.price)
        }
    }

    prices.sort((a, b) => a - b);

    return prices

}

const ModelStats = (props) => {

    const priceArray = getPrices(props.ads.edges)
    const sum = priceArray.reduce((a, b) => a + b, 0);
    const avg = Math.round((sum / priceArray.length)) || 0;
    const min = Math.min(...priceArray);
    const max = Math.max(...priceArray);
    const median = priceArray[Math.round(priceArray.length / 2)];

    const StatItem = (props) => (
        <Box
            borderRadius="lg"
            borderColor="gray.100"
            backgroundColor="white"
            shadow="sm"
            p=".75rem"
        >
            <Text textTransform="uppercase" fontSize="13px" color="blue.600" letterSpacing=".05rem">
                <FormattedMessage id={props.label} />&nbsp;:
            </Text>
            <Text fontSize={{ base: '18p', lg: '20px' }} fontWeight="bold" color="gray.600">{props.value}</Text>
        </Box>
    )
    return (
        <Box
            id="stats"
        >
            <Heading as="h3" color="gray.700" fontSize={{ base: "16px", lg: "17px" }} mb="1rem">
                <FormattedMessage id="model.stats.title" values={{ modelName: props.modelName, universName: props.universName }} />
                &nbsp;:
            </Heading>
            <SimpleGrid
                columns={{ base: 2, md: 4, lg: 2 }}
                gap="1rem"
                alignItems="center"
                justifyContent="center"
                border="solid 1px blue.300"
                borderColor="blue.300"
            >
                {min && min !== Infinity ? <StatItem label="model.stats.min.price" value={min + '€'} /> : null}
                {max && max !== -Infinity ? <StatItem label="model.stats.max.price" value={max + '€'} /> : null}
                {avg ? <StatItem label="model.stats.average.price" value={avg + '€'} /> : null}
                {median ? <StatItem label="model.stats.median.price" value={median + '€'} /> : null}
                <StatItem label="model.stats.available.boats" value={priceArray.length} />
                {props.soldBoats ? <StatItem label="model.stats.sold.boats" value={props.soldBoats} /> : null}
                {props.launchYear ? <StatItem label="model.stats.launch.year" value={props.launchYear} /> : null}
                {props.boatDesigner ? <StatItem label="model.stats.designer" value={props.boatDesigner} /> : null}
                {props.boatCrew ? <StatItem label="model.stats.crew" value={props.boatCrew} /> : null}
                {props.boatWeight ? <StatItem label="model.stats.weight" value={props.boatWeight + 'kg'} /> : null}
            </SimpleGrid>
        </Box>
    )
}

export default ModelStats